import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { Embellishment0, Embellishment22 } from "../components/Embellishments"
import Layout from "../components/Layout"

import "../css/blog.scss"

function Section1({ title, subtitle }) {
  const titleElement = (
    <h1 className="h1 text---white" itemProp="title">
      {title}
    </h1>
  )
  const subtitleElement = <h2 className="h2 text---white">{subtitle}</h2>
  return (
    <div className="section">
      <div className="padding---subpage-hero">
        <div className="subpage-hero-interior-padding background---black">
          {titleElement}
          {subtitleElement}
          <Embellishment22 />
        </div>
      </div>
    </div>
  )
}

function Section2({ effectiveDate, lastUpdate, body }) {
  return (
    <section className="section">
      <header className="xl---padding-12---1-col md---block">
        <p className="effective_date date" itemProp="startDate">
          Effective: {effectiveDate}
        </p>
        <p className="last_update_date date" itemProp="dateModified">
          Last modified: {lastUpdate}
        </p>
      </header>
      <div
        className="xl---padding-12---1-col spacing---lg md---block legal-body"
        dangerouslySetInnerHTML={{ __html: body }}
      />
      <div>
        <Embellishment0 />
      </div>
      <div className="section">
        <div className="xl---padding-12---2-col lg---padding-12---1-col">
          <div className="xl---v-padding---2 xl---flex md---flex-vertical">
            <div className="xl---width-8---2h float---left lg---width-10---3 md---full-width md---center">
              <div className="vertical-center">
                <strong>
                  Interested in hearing more?{" "}
                  <a href="mailto:contact@thesis.co">contact@thesis.co</a>
                </strong>
              </div>
            </div>
            <div className="xl---width-8---5h float---left lg---width-10---7 md---full-width">
              <div className="email-form-block w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  className="form"
                >
                  <input
                    type="email"
                    className="email-form w-input"
                    maxLength="256"
                    name="email-2"
                    data-name="Email 2"
                    placeholder="Email Address"
                    id="email-2"
                    required=""
                  />
                  <input
                    type="submit"
                    value="Submit"
                    data-wait="Please wait..."
                    className="email-form-submit w-button"
                  />
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function LegalPage({ data }) {
  const { markdownRemark: post } = data
  const {
    frontmatter: { title, subtitle },
  } = post

  return (
    <Layout className="blog-page legal-page">
      <Helmet>
        <title>
          {subtitle && subtitle !== "" ? `${title}: ${subtitle}` : title}
        </title>
      </Helmet>
      <Section1 {...post.frontmatter} />
      <Section2 body={post.html} {...post.frontmatter} />
    </Layout>
  )
}

LegalPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default LegalPage

export const pageQuery = graphql`
  query LegalPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        effectiveDate(formatString: "MMMM DD YYYY")
        lastUpdate(formatString: "MMMM DD YYYY")
        title
        subtitle
      }
    }
  }
`
